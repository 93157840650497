<template>
    <PublicForm :busy="busy">
        <v-form v-model="form.valid">
            <v-row dense>
                <v-col class="py-8">
                    <BreezeLogo style="height: 50px" max-height="50" />
                </v-col>
            </v-row>
            <v-row v-if="!tokenValid" dense>
                <v-col cols="12" class="px-6">
                    <LoginPageError :error="error" />
                    <p>Please enter your password reset token below.</p>
                </v-col>
                <v-col cols="12" class="px-6">
                    <v-text-field
                        label="Password Reset Token"
                        ref="token"
                        name="token"
                        v-model="form.token"
                        prepend-icon="mdi-key"
                        type="text"
                        :rules="[(v) => !!v || 'Token is required']"
                        :disabled="busy"
                        v-on:keyup.enter="validateToken"
                        required
                        autofocus
                    />
                </v-col>
                <v-col style="min-height: 102px" class="pt-8 px-6" cols="12">
                    <v-btn
                        v-on:click="validateToken"
                        color="primary"
                        :disabled="busy || !form.valid"
                        block
                        large
                        >Validate Password Reset Token</v-btn
                    >
                </v-col>
            </v-row>
            <v-row v-if="tokenValid && !resetSuccess" dense>
                <v-col cols="12" class="px-6">
                    <LoginPageError :error="error" />
                    <p>
                        You have requested to reset the password for
                        <b>{{ tokenData.username }}</b>.
                    </p>
                    <p>Please enter your new password below.</p>
                </v-col>
                <v-col cols="12" class="px-6">
                    <v-text-field
                        label="New Password"
                        ref="password"
                        name="password"
                        v-model="form.password"
                        prepend-icon="mdi-lock"
                        type="password"
                        :rules="[
                            (v) => !!v || 'Password is required',
                            (v) =>
                                /[A-Z]/.test(v) ||
                                'Password must contain at least 1 uppercase letter',
                            (v) =>
                                /[a-z]/.test(v) ||
                                'Password must contain at least 1 lowercase letter',
                            (v) =>
                                /\d/.test(v) ||
                                'Password must contain at least 1 number',
                            (v) =>
                                /.{8}/.test(v) ||
                                'Password must be at least 8 characters long',
                        ]"
                        :disabled="busy"
                        v-on:keyup.enter="resetPassword"
                        required
                        autofocus
                    />
                    <v-text-field
                        label="Confirm New Password"
                        ref="confirm_password"
                        name="confirm_password"
                        prepend-icon="mdi-lock"
                        v-model="form.confirm_password"
                        type="password"
                        :rules="[
                            (v) =>
                                v === this.form.password ||
                                'Passwords do not match',
                        ]"
                        :disabled="busy"
                        v-on:keyup.enter="resetPassword"
                        required
                    />
                </v-col>
                <v-col style="min-height: 102px" class="pt-8 px-6" cols="12">
                    <v-btn
                        v-on:click="resetPassword"
                        color="primary"
                        :disabled="
                            busy ||
                            !form.valid ||
                            form.password !== form.confirm_password
                        "
                        block
                        large
                        >Reset Password</v-btn
                    >
                </v-col>
            </v-row>
            <v-row v-if="resetSuccess" dense>
                <v-col cols="12" class="px-6 pb-6 text-center">
                    <v-icon size="120" color="success"
                        >mdi-check-circle-outline</v-icon
                    >
                </v-col>
                <v-col cols="12" class="px-6">
                    <p>Your password has been reset successfully.</p>
                    <p>
                        If you still cannot access your account, please
                        <a href="mailto:help@breezeconnect.com.au">contact Breeze Connect Support</a>
                        for assistance.
                    </p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col class="text-center my-3" :disabled="busy">
                    <router-link
                        :to="{ name: 'Login' }"
                        :disabled="!busy"
                        :event="!busy ? 'click' : ''"
                        v-bind:class="{ 'text--disabled': busy }"
                        >Return to login page</router-link>
                </v-col>
            </v-row>
        </v-form>
    </PublicForm>
</template>

<script>
import PublicForm from '../../components/pieces/Public/PublicForm';
import BreezeLogo from '../../components/pieces/Public/BreezeLogo';
import LoginPageError from '../../components/pieces/Public/LoginPageError';
import apiMixin from '../../mixins/apiMixin';
export default {
    name: 'ResetPassword',
    mixins: [apiMixin],
    data() {
        return {
            form: {
                token: '',
                password: '',
                confirm_password: '',
            },
            busy: false,
            error: '',
            tokenData: {},
            tokenValid: false,
            resetSuccess: false,
        };
    },
    methods: {
        async validateToken(event) {
            event.preventDefault();
            if (!this.form.token) return;
            await this.getTokenData();
        },
        async getTokenData() {
            this.busy = true;
            const response = await this.Api.send('post','auth/reset/validate', {
                token: this.form.token,
            });
            if (response.success) {
                this.tokenValid = true;
                this.tokenData = response.data;
                this.busy = false;
            } else {
                this.error =
                    response.errorDetail || 'An unknown error occurred';
                this.busy = false;
            }
        },
        async resetPassword(event) {
            event.preventDefault();
            if (!this.form.token) return;
            this.busy = true;
            const response = await this.Api.send('post','auth/reset/submit', {
                token: this.form.token,
                password: this.form.password,
            });
            if (response.success) {
                this.resetSuccess = true;
                this.busy = false;
            } else {
                this.error =
                    response.errorDetail || 'An unknown error occurred';
                this.busy = false;
            }
        },
    },
    computed: {
        theme: function () {
            if (this.$vuetify.theme.dark) {
                return 'dark';
            } else {
                return 'light';
            }
        },
    },
    created() {
        if (this.$route.query.token) {
            this.form.token = this.$route.query.token;
            this.getTokenData();
        }
    },
    components: {
        LoginPageError,
        BreezeLogo,
        PublicForm,
    },
};
</script>
